import { Component, OnInit } from '@angular/core';
import Speech from 'speak-tts';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

	public text: string = '';
	public voiceType: string = 'normal';

	private speech: Speech;

	constructor() { }

	onValidate() {
		this.speech.speak({
			text: this.text,
		}).then(() => {
			console.log("Success !")
		}).catch(e => {
			console.error("An error occurred :", e)
		})
	}

	onVoiceChange() {
		if (this.voiceType == 'normal') {
			this.speech.setRate(1);
			this.speech.setPitch(1);
		} else if (this.voiceType == 'kido') {
			this.speech.setRate(0.6);
			this.speech.setPitch(2);	
		}
	}

	ngOnInit() {
		this.speech = new Speech() // will throw an exception if not browser supported
		if (this.speech.hasBrowserSupport()) { // returns a boolean
			console.log("speech synthesis supported")
			this.speech.init().then((data) => {
				// The "data" object contains the list of available voices and the voice synthesis params
				console.log("Speech is ready, voices are available", data)
				this.speech.init({
					'volume': 1,
					'lang': 'fr-FR',
					'rate': 1,
					'pitch': 1,
					'voice': 'Google français',
					'splitSentences': true,
					'listeners': {
						'onvoiceschanged': (voices) => {
							console.log("Event voiceschanged", voices)
						}
					}
				})
			}).catch(e => {
				console.error("An error occured while initializing : ", e)
			})
		}
	}
}
